import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@mui/material";

const CommonCard = ({ title, location, imgSrc, link, onClick }) => {
  return (
    <Card
      sx={{
        maxWidth: 345,
        mx: "auto",
        borderRadius: "16px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "&:hover": {
          transform: "scale(0.95)",
          transition: "transform 0.5s ease",
        },
      }}
      onClick={onClick}
    >
      <CardActionArea
        component="a"
        href={link}
        target="_blank"
        rel="noreferrer"
        sx={{ flexGrow: 1 }}
      >
        <CardMedia
          component="img"
          height="200"
          image={imgSrc}
          alt={title}
          sx={{ borderRadius: "16px 16px 0 0" }}
        />
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontFamily: "merriweather",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontFamily: "merriweather",
            }}
          >
            {location}
          </Typography>
        </CardContent>
      </CardActionArea>
      {link && (
        <CardContent>
          <Button
            variant="outlined"
            href={link}
            target="_blank"
            rel="noreferrer"
            fullWidth
            sx={{
              borderRadius: "8px",
              color: "black",
              textTransform: "none",
              borderColor: "#dddddd",
              overflow: "hidden",
              fontFamily: "merriweather",
              "&:hover": {
                background: "white",
              },
            }}
          >
            {link}
          </Button>
        </CardContent>
      )}
    </Card>
  );
};

export default CommonCard;
