import React from "react";
// import bg from "../../assets/photos/TSGOC_Website-page1.jpg"

function Associates() {
  const phoneNumber = "9990048989";
  return (
    <div
      className="p-4 flex flex-col py-10 md:pb-96 md:pt-20 gap-5 bg-[#ffff] bg-opacity-75 bg-cover bg-center"
      // data-scroll data-scroll-section data-scroll-speed="-.2"
      // style={{ backgroundImage: `url(${bg})` }}
    >
      <h2 className="text-3xl md:text-5xl  text-center bg-cover">Associates</h2>
      <p className="text-lg md:text-2xl md:m-20 md:my-4 mb-10 px-1  text-justify">
        At The Shubham Group, we highly value our associates as an integral part
        of our business. We firmly believe in building long-term partnerships
        and conducting transparent deals from all sides. Your expertise and
        insights are crucial to the success of our company, and we are confident
        that your involvement will significantly contribute to its growth.
        <br />
        If you are interested in joining us, please let us know. Also, feel free
        to contact Sales Manager Mr. Rahul Aggarwal directly at{" "}
        <a
          className=" text-xl hover:underline hover:text-blue-400 font-semibold"
          href={`tel:${phoneNumber}`}
        >
          8826278989
        </a>{" "}
        if you have any questions or require more information about our
        projects. We will be happy to assist you in any way possible.
      </p>
    </div>
  );
}
export default Associates;
