import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Typography } from "@mui/material";
import CommonCard from "../card";
import Grid from "@mui/material/Grid2";
import config from "../../config";

function Project1_Residential() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  const projects = [
    {
      title: "Indus Valley",
      location: "Mukteshwar Nainital",
      imgSrc: `${config.S3_URL}/mukteshwarLogo.png`,
      link: "http://www.indusvalleymukteshwar.com",
    },
    {
      title: "Queens Land",
      location: "Ranikhet",
      imgSrc: `${config.S3_URL}/queensland.png`,
      link: "https://indusvalleyranikhet.com/",
    },
    {
      title: "Chail Heights Villas",
      location: "Himachal Pradesh",
      imgSrc: `${config.S3_URL}/ChailVillaLogo.png`,
      link: "http://www.chailheightsvillas.com",
    },
  ];

  return (
    <div
      data-aos-anchor-placement="top-center"
      data-aos="fade-up"
      className="mt-12"
    >
      <Container>
        <section className="flex flex-col justify-center gap-5 lg:gap-16 p-3">
          <Typography
            // variant="h3"
            align="center"
            gutterBottom
            style={{
              letterSpacing: "0.05em",
              textShadow: "5px 5px 8px #767e8c",
              fontSize: "32px",
            }}
          >
            Residential
          </Typography>
        </section>

        <Grid container spacing={4}>
          {projects.map((project, index) => (
            <Grid item xs={12} sm={6} lg={4} key={index}>
              <CommonCard
                title={project.title}
                location={project.location}
                imgSrc={project.imgSrc}
                link={project.link}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default Project1_Residential;
