import React from "react";
import config from "../../config";

const Blog2 = () => {
  return (
    <div className="flex flex-col justify-center items-center bg-white">
      <img
        className="p-3 md:p-5 xl:p-10"
        src={`${config.S3_URL}/blog-second-home.webp`}
        alt="Why Second Homes are the Future of Smart Real Estate Investment"
      />
      <div className="w-full max-w-6xl my-10 flex flex-col gap-5">
        <h1 className="text-2xl md:text-4xl  text-red-800 text-center">
          Why Second Homes are the Future of Smart Real Estate Investment
        </h1>
        <p className="text-md md:text-xl px-3 text-justify rounded-2xl">
          Living in an urbanized colony around a concrete jungle, there must
          have been a time when most of the individuals living in these cities
          wished to have a small place on hills where they could relax from
          their fast-paced lifestyle and enjoy the natural beauty of the
          Himalayas. A place beyond daily tensions, where they can rethink,
          redevelop new thoughts, and reinvent themselves.
        </p>
        <h2 className="text-lg md:text-3xl font-semibold pt-5 text-center bg-cover">
          What is a second home or a vacation home?
        </h2>
        <p className="text-md md:text-xl px-3  text-justify rounded-2xl">
          There was a time when these second homes were seen as a statement of
          luxury, but now they are the need of the hour for the people living in
          political cities. These aesthetic cottages on hills are worth every
          penny you are paying for as there is a high appreciation recorded on
          these properties, especially post-COVID, as people have started
          realizing the value of a peaceful environment.
        </p>

        <h3 className="text-lg md:text-3xl  font-semibold pt-5 text-center bg-cover">
          Discover Peace, Discover Indus Valley
        </h3>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl">
          <strong>Indus Valley Mukteshwar</strong> is a project that sells not
          just cottages to its customers but also the environment and experience
          they create around you. Offering Himalayan-facing cottages at 7800
          feet from sea level is not easy. Indus Valley has created a small
          world far away.
        </p>

        <h3 className="text-lg md:text-3xl  font-semibold pt-5 text-center bg-cover">
          Some Reasons why someone should invest in Indus Valley Mukteshwar,
          Nainital
        </h3>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl">
          <span className="font-semibold">Property Appreciation:</span> Post
          covid, there was a sudden change in the market trend and customer
          taste. They realized the need for a place to be secluded with their
          loved ones. That’s why properties on hill stations have witnessed a
          great hike in their market value. If we talk about the Indus Valley in
          particular, there was a 40% increase in the value of the land in just
          the last three years.
        </p>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl">
          <span className="font-semibold">
            Personal family time with loved ones:
          </span>{" "}
          Who doesn’t want to spend time with their family? A warm, small
          cottage in the lap of the Himalayas is the perfect way to express your
          feelings for your family. Create a Legacy and Family Asset for
          generations.
        </p>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl">
          <span className="font-semibold">Potential for Rental Income:</span>{" "}
          Return on investment is one of the key things that many individuals
          are concerned about, and for that, after purchasing a property at
          Indus Valley, you can rent out your cottage with Indus Valley
          homestay, or you can rent it out by yourself on various hotel booking
          platforms like Airbnb, booking.com, MakeMyTrip.
        </p>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl">
          <span className="font-semibold">
            Relief from hot and humid weather:
          </span>{" "}
          Places like Delhi NCR are overcrowded and have become a concrete
          jungle of buildings and roads. The temperatures are going beyond
          expectations; heat waves are one of the most crucial phases that
          people have to deal with, and that’s why a place in the mountains is
          essential for residents from plain areas.
        </p>
      </div>
    </div>
  );
};

export default Blog2;
