import React from "react";

import { Link } from "react-router-dom";

import icon_facebook from "./screens/icons2/icon_facebook.png";
import icon_twitter from "./screens/icons2/icon_twitter.png";
import icon_insta from "./screens/icons2/icon_insta.png";
import icon_yt from "./screens/icons2/icon_yt.png";
import icon_linkedin from "./screens/icons2/icon_linkedin.png";
import TSGOC_LOGO_F_8 from "../assets/logos/SHUBHUM GROUP-LastLogo.png";

const Footer = () => {
  const phoneNumber = "8510850101";

  return (
    <footer
      id="contact"
      style={{
        backgroundColor: "white",
        paddingBottom: 5,
      }}
    >
      <div
        style={{
          padding: 24,
          borderBottomWidth: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
          className="gap-8"
        >
          <a href="https://www.facebook.com/theshubhamGroup/" target="_blank">
            <img
              className="w-8 md:p-1 md:rounded-lg animate__zoomIn2 sm:w-12"
              src={icon_facebook}
              alt="Facebook Link.."
            />
          </a>

          <a href="https://twitter.com/theshubhamgroup" target="_blank">
            <img
              className="w-8 md:p-1 md:rounded-lg animate__zoomIn2 sm:w-12"
              src={icon_twitter}
              alt="Twitter Link.."
            />
          </a>

          <a href="https://www.instagram.com/theshubhamgroup/" target="_blank">
            <img
              className="w-8 md:p-1 md:rounded-lg animate__zoomIn2 sm:w-12"
              src={icon_insta}
              alt="Insta Link.."
            />
          </a>

          <a
            href="https://www.youtube.com/@indusvalleymukteshwar"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-8 md:p-1 md:rounded-lg animate__zoomIn2 sm:w-12"
              src={icon_yt}
              alt="Youtube Link.."
            />
          </a>

          <a
            href="https://www.linkedin.com/in/the-shubham-group-of-company/"
            target="_blank"
          >
            <img
              className="w-8 md:p-1 md:rounded-lg animate__zoomIn2 sm:w-12"
              src={icon_linkedin}
              alt="LinkedIn Link.."
            />
          </a>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            justifyContent: "space-evenly",
            margin: 24,
          }}
        >
          <section>
            <p className="text-black text-l md:text-xl font-semibold">
              Registered Office
            </p>
            <p className="text-zinc-600 text-lg  font-thin ">
              The Shubham Group
              <br />
              A-24, Okhla Industrial Area, Phase-2, Delhi, Pincode - 110020
            </p>
          </section>

          <section>
            <p className="text-black text-l md:text-xl font-semibold">
              Corporate Office
            </p>
            <p className="text-zinc-600 text-lg  font-thin  ">
              315, Block-B, Sector-105 Noida, Uttar Pradesh, Pincode - 201304
            </p>
          </section>

          <section>
            <p className="text-black text-l md:text-xl font-semibold">
              Contact Us
            </p>
            <div className="flex flex-col ">
              <a
                className="text-zinc-600 text-lg  hover:text-blue-400 "
                href="mailto:info@tsgoc.in"
              >
                {" "}
                info@theshubhamgroup.com
              </a>
              <a
                className="text-zinc-700  text-lg  hover:black"
                href={`tel:${phoneNumber}`}
              >
                8510 850 101
              </a>
            </div>
          </section>
        </div>
        <section style={{ alignContent: "center" }}>
          <img
            style={{ width: 300, height: 100, objectFit: "contain" }}
            src={TSGOC_LOGO_F_8}
            alt="Linkdin Link.."
          />
        </section>
      </div>
      <section className="copy-right">
        <p className="text-zinc-700  text-center text-sm  p-3 pt-0">
          Copyright © 2009 The Shubham Group (all rights reserved)
        </p>
      </section>
    </footer>
  );
};

export default Footer;
