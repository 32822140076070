import React from "react";
import { commonStyle } from "../../constants/constant";

// import bg from "../../assets/photos/TSGOC_Website-page1.jpg"
// import bg5 from "./Background_img/bg5.avif";

function Investors() {
  return (
    <div
      // className="p-4 flex flex-col py-10 md:pb-24 md:pt-10  gap-5 bg-cover bg-center bg-[#E0BF8D] bg-opacity-75"
      style={commonStyle}
      // data-scroll data-scroll-section data-scroll-speed=".2"
      // style={{ backgroundImage: `url(${bg})` }}
    >
      <h2 className="text-3xl md:text-5xl md:pt-5  text-center bg-cover">
        Investors
      </h2>

      <p className="text-lg md:text-2xl md:m-20  md:my-5 px-1 text-justify">
        Real estate investors have different preferences when it comes to the
        type of properties they focus on. Some investors may choose to invest in
        commercial properties such as shopping centers or office buildings,
        while others may prefer apartment buildings or single-family homes. Many
        investors seek a steady income stream from rental properties, while some
        may be more interested in flipping properties to profit from
        appreciation. No matter what type of real estate investment you choose,
        it’s important to consider the return on investment (ROI) when making a
        decision. At The Shubham Group, we are committed to providing a variety
        of returns on our properties, including appreciation of land, building
        up cottages, apartments, and rental income. Over the past decade, our
        properties have shown a 100% growth in value in the hills real estate
        market. Our team is motivated and always encourages transparency in our
        business transactions. We're ready to provide our investors with a
        convenient way to access all the company information and regulatory
        disclosures. If you're looking for the best options to invest in real
        estate or property in the hills, Uttarakhand, vacation or holiday homes,
        or even a monthly rental, we are here to assist you.
      </p>
    </div>
  );
}

export default Investors;
