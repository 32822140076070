import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import TSGOC_LOGO_F_8 from "../assets/logos/SHUBHUM GROUP-LastLogo.png";

function Header() {
  const [toggle, setToggle] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when route changes
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`sticky top-0 z-50 transition-all duration-300 ease-in-out bg-white shadow-lg`}
    >
      <div className="container flex justify-between items-center h-[105px] px-3 sm:px-5 md:px-10 md:ml-[18px] mr-[18px]">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img
            className="w-36 md:w-50 pb-1 rounded-full transition-transform duration-300 hover:scale-105"
            src={TSGOC_LOGO_F_8}
            alt="logo"
          />
        </Link>

        {/* Hamburger Icon for Mobile */}
        <div className="md:hidden">
          {!toggle && (
            <AiOutlineMenu
              onClick={() => setToggle(!toggle)}
              className="text-3xl cursor-pointer transition-transform transform hover:scale-110"
            />
          )}
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-8 text-lg font-medium">
          <Link
            to="/"
            className="transition-transform transform hover:scale-110"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="transition-transform transform hover:scale-110"
          >
            About Us
          </Link>
          <Link
            to="/our projects"
            className="transition-transform transform hover:scale-110"
          >
            Our Projects
          </Link>
          <Link
            to="/contact"
            className="transition-transform transform hover:scale-110"
          >
            Reach Us
          </Link>
          <Link
            to="/blogs"
            className="transition-transform transform hover:scale-110"
          >
            Blogs
          </Link>
        </nav>

        {/* Mobile Navigation */}
        <nav
          className={`fixed inset-0 bg-black bg-opacity-75 flex flex-col items-center justify-center space-y-10 text-white text-2xl md:hidden transition-all duration-500 transform ${
            toggle ? "translate-x-0" : "-translate-x-full"
          }`}
          style={{ zIndex: 1000 }}
        >
          {toggle && (
            <AiOutlineClose
              onClick={() => setToggle(!toggle)}
              className="absolute top-5 right-5 text-3xl cursor-pointer"
            />
          )}
          <Link onClick={() => setToggle(false)} to="/" className="p-5">
            Home
          </Link>
          <Link onClick={() => setToggle(false)} to="/about" className="p-5">
            About Us
          </Link>
          <Link
            onClick={() => setToggle(false)}
            to="/our projects"
            className="p-5"
          >
            Our Projects
          </Link>
          <Link onClick={() => setToggle(false)} to="/contact" className="p-5">
            Reach Us
          </Link>
          <Link onClick={() => setToggle(false)} to="/blogs" className="p-5">
            Blogs
          </Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;
