import React from "react";
// import bg from "../../assets/photos/TSGOC_Website-page1.jpg";
import Project1Residential from "./Project1_Residential";
import Project2Resorts from "./Project2_Resorts";
import Project3Trading from "./Project3_Trading";
import ProjectDelivered from "./ProjectDelivered";
import Project2_1_Homestay from "./Project2_1_Homestay";
import { commonStyle } from "../../constants/constant";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

function Our_Projects() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
      style={commonStyle}
    >
      <Helmet>
        <title>Our Projects | Shubham Buildup Pvt. Ltd.</title>
        <meta
          name="description"
          content="Explore our projects and get to know about the residential, resorts, and trading projects we have delivered."
        />
      </Helmet>
      <h3
        className="text-3xl md:text-5xl md:pt-5 text-center "
        style={{
          letterSpacing: "0.05em",
          textShadow: "5px 5px 8px #767e8c",
        }}
      >
        Explore Our Projects
      </h3>

      <Project1Residential />
      <div className="flex flex-col py-10 gap-10">
        <Project2Resorts />
        <Project2_1_Homestay />
        <Project3Trading />
      </div>
      <ProjectDelivered />
    </div>
  );
}

export default Our_Projects;
