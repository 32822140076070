import React, { useRef } from "react";
import Slider from "react-slick";
import config from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

const imageUrls = [
  `${config.S3_URL}/Banner_1.webp`,
  `${config.S3_URL}/Banner_2.webp`,
  `${config.S3_URL}/Banner_3.webp`,
  `${config.S3_URL}/Banner_4.webp`,
  `${config.S3_URL}/Banner_5.webp`,
  `${config.S3_URL}/Banner_6.webp`,
  `${config.S3_URL}/Banner_7.webp`,
  `${config.S3_URL}/Banner_8.webp`,
  `${config.S3_URL}/Banner_1.png`,
  `${config.S3_URL}/Banner_2.jpeg`,
  `${config.S3_URL}/Banner_3.jpeg`,
  `${config.S3_URL}/Banner_4.jpg`,
  `${config.S3_URL}/Banner_5.png`,
  `${config.S3_URL}/Banner_6.jpeg`,
  `${config.S3_URL}/Banner_7.jpeg`,
  `${config.S3_URL}/Banner_8.jpeg`,
  `${config.S3_URL}/Banner_10.jpeg`,
  `${config.S3_URL}/Banner_12.jpeg`,
  `${config.S3_URL}/Banner_13.jpeg`,
  `${config.S3_URL}/Banner_14.jpeg`,
  `${config.S3_URL}/Banner_15.jpeg`,
  `${config.S3_URL}/Banner_16.jpeg`,
];

const Banner = () => {
  const sliderRef = useRef(null);

  // Slider settings
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    fade: true,
    arrows: true,
    prevArrow: (
      <button
        type="button"
        className="slick-prev"
        style={{
          zIndex: 1000,
        }}
      >
        <FontAwesomeIcon icon={faCaretLeft} size="2x" />
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next">
        <FontAwesomeIcon icon={faCaretRight} size="2x" />
      </button>
    ),
  };

  return (
    <div>
      <Helmet>
        <title>Shubham Buildup Pvt. Ltd.</title>
        <meta
          name="description"
          content="Shubham Buildup Pvt. Ltd. is a real estate company that provides residential, resorts, and trading projects."
        />
      </Helmet>
      <Slider {...settings} ref={sliderRef}>
        {imageUrls.map((item, index) => (
          <div key={index}>
            <img
              src={item}
              className="h-[90vw] md:h-[36vw] w-full object-cover"
              alt="banner"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Banner;
