import React from "react";

import config from "../../config";

import { commonStyle } from "../../constants/constant";
import { Card, CardContent } from "@mui/material";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <div>
        <img
          src={`${config.S3_URL}/reach-us-banner.png`}
          className="w-full h-48 sm:h-72 md:h-80 lg:h-96 object-cover"
          alt="contact"
          loading="lazy"
        />
      </div>
      <Helmet>
        <title>Contact Us | Shubham Buildup Pvt. Ltd.</title>
        <meta
          name="description"
          description="Get in touch with us for any queries or information. Reach out to us at our registered and corporate offices."
        />
      </Helmet>

      <div className="flex flex-col md:flex-row justify-center items-center sm:flex-col gap-8 m-8">
        <section style={commonStyle}>
          <p
            style={{
              marginBottom: 24,
              fontSize: 22,
              fontWeight: "700",
              letterSpacing: 1,
              borderBottomWidth: 2,
            }}
          >
            Registered Office
          </p>
          <p
            style={{
              marginBottom: 24,
              fontSize: 18,
              fontWeight: "400",
              letterSpacing: 1,
            }}
          >
            The Shubham Group <br />
            A-24, Okhla Industrial Area, Phase-2,Delhi, Pincode - 110020
          </p>
          <a href="tel:+918510850101">
            <p
              style={{
                borderTopWidth: 2,
                paddingTop: 24,
                fontSize: 18,
                cursor: "pointer",
              }}
            >
              +91 8510850101
            </p>
          </a>
        </section>

        <section style={commonStyle}>
          <p
            style={{
              marginBottom: 24,
              fontSize: 24,
              fontWeight: "700",
              letterSpacing: 1,
              borderBottomWidth: 2,
            }}
          >
            Corporate Office
          </p>
          <p
            style={{
              marginBottom: 24,
              fontSize: 18,
              fontWeight: "400",
              letterSpacing: 1,
            }}
          >
            315, Block-B,
            <br />
            Sector-105 Noida,Uttar Pradesh, Pincode - 201304
          </p>
          <a href="tel:+918826278989">
            cursor: "pointer",
            <p
              style={{
                borderTopWidth: 2,
                paddingTop: 24,
                fontSize: 18,
                cursor: "pointer",
              }}
            >
              +91 8826278989
            </p>
          </a>
        </section>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 24,
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: 3,
            borderRadius: 2,
            width: {
              xs: "100%",
              md: "50%",
            },
            alignSelf: "center",
          }}
        >
          <CardContent
            sx={{
              width: "100%",
              padding: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{ fontFamily: "unset", fontWeight: "700", fontSize: 28 }}
            >
              Post Your Request
            </span>

            <iframe
              id="Contact Us - TSG"
              src="https://account.solidperformers.com/capture_form_data/MTI0MA=="
              className="text-3xl md:text-4xl w-full md:w-1/2 md:py-8 lg:px-20 h-96 text-white rounded-2xl font-semibold"
              style={{
                width: "100%",
              }}
            ></iframe>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Contact;
