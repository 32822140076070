import { Card } from "@mui/material";
import React from "react";

function MainHeading() {
  return (
    <Card
      className="relative mx-auto w-fit mt-[26px] md:mt-[12px] lg:mt-[12px]"
      sx={{
        borderRadius: "12px",
        alignSelf: "center",
        padding: "12px",
        boxShadow: "2px 2px 16px 0 rgba(0,0,0,0.1)",
        margin: {
          xs: "12px",
          md: "24px",
        },
      }}
    >
      <h1
        className="text-lg md:text-5xl lg:text-6xl mx-auto text-center text-gray-800 font-semibold"
        style={{
          marginTop: "12px",
        }}
      >
        The Shubham Group
      </h1>
      <h2
        className="text-sm md:text-3xl lg:text-4xl mx-auto text-center m-2 mb-0 pb-0 px-2 text-gray-600"
        style={{
          marginTop: "12px",
          marginBottom: "12px",
        }}
      >
        Real Estate, Construction, Resorts and Collaboration
      </h2>
    </Card>
  );
}

export default MainHeading;
