import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from "@mui/material";

const BlogCard = ({ image, title, date, href }) => {
  return (
    <Card
      sx={{
        width: "100%",
        paddingBottom: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        transition: "transform 0.5s",
        borderRadius: 2,
        overflow: "hidden",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      <CardActionArea href={href} target="_blank">
        <CardMedia
          component="img"
          image={image}
          alt={title}
          loading="lazy"
          sx={{
            width: "100%",
            borderRadius: "8px 8px 0 0",
          }}
        />
        <CardContent sx={{ padding: 2 }}>
          <Typography
            variant="h6"
            component="h2"
            sx={{
              fontFamily: "merriweather",
              fontSize: "24px",
              fontWeight: 500,
              lineHeight: 1,
              letterSpacing: "0.00938em",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            align="right"
            sx={{
              fontFamily: "merriweather",
            }}
          >
            {date}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BlogCard;
